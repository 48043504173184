<template>
  <Modal
      id="FormModel"
      width="50%"
      :open="open"
      @cancel="open = !open"
      @ok="submit"
      :title="title"
      :confirmLoading="confirmLoading"
  >
    <Row align="middle" justify="center">
      <Col span="20">
        <Divider orientation="left">Status</Divider>
        <Descriptions>
          <DescriptionsItem v-for="(item, index) in attributes.status" :key="index" :label="(isZh ? item.name : item.code) + ( item.values.unit === undefined ? '' : '(' + item.values.unit + ')' )">
            {{ statusObj[item.code] / Math.pow(10, item.values?.scale) }}
          </DescriptionsItem>
        </Descriptions>
      </Col>
    </Row>

    <Row align="middle" justify="center">
      <Col span="20" >
        <Divider orientation="left">Functions</Divider>
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <template v-for="(item, index) in attributes.functions" :key="index">
                  <Col span="10" v-if="item['type'] !== 'Raw'">
                    <FormItem :label="(isZh ? item.name : item.code)" :name="item.code">
                      <Input v-if="item['type'] === 'String'" :placeholder="item.desc" v-model:value="form[item.code]"></Input>
                      <Select v-if="item['type'] === 'Enum'" :placeholder="item.desc" v-model:value="form[item.code]" :dropdownStyle="{ zIndex: 9999999950 }">
                        <SelectOption :key="sk" v-for="(it,sk) in item.values.range" :value="it">{{ it }}</SelectOption>
                      </Select>
                      <InputNumber v-if="item['type'] === 'Integer'" :placeholder="item.desc" v-model:value="form[item.code]" :step="item.values.step" :min="item.values.min" :max="item.values.max" style="width: 100%"></InputNumber>
                      <Switch v-if="item['type'] === 'Boolean'" :placeholder="item.desc" v-model:value="form[item.code]" :checked="form[item.code]" @click="e => switchClick(e, item.code)"></Switch>
                    </FormItem>
                  </Col>
                </template>
                <Col span="10"></Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "EditModel"
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputNumber,
  Switch,
  Descriptions,
  DescriptionsItem,
  message,
  Select,
  SelectOption,
  Divider
} from "ant-design-vue";

import {
  DeviceStatus,
  DeviceFunctions,
  DeviceSendFunction,
  DeviceSpecificationAttributes,
  DeviceSendAction, DeviceSendProperties
} from "@/api/tuya";
import {ref} from "vue";

import {useI18n} from "vue-i18n";

const i18n = useI18n()

const emits = defineEmits(['updateList'])

const refForm = ref()

let title = ref("")
let open = ref(false)
let confirmLoading = ref(false)

let isZh = ref(i18n.locale.value === 'zhTW' || i18n.locale.value === 'zhCN')

const deviceId = ref("")
const attributes = ref({})
const statusList = ref([])
const statusObj = ref({})
const functionList = ref([])

let form = ref({})
const validateRules = {
  ids: [
    {
      required: false,
      message: i18n.t("chargerManage.validate.id"),
      trigger: "change",
    },
  ],
}

function showEdit(obj) {
  deviceId.value = obj.id;
  title.value = i18n.t("public.name.setting") + "-" + obj.id;
  _resetData();
  open.value = true;

  getDeviceSpecificationAttributes(obj.id)
  getStatus(obj.id)
  // getFunction(obj.uuid)
}

function submit() {
  refForm.value.validate().then(() => {
    confirmLoading.value = true
    sendProperties(form.value)
  }).catch(() => {});
}

function _resetData() {
  form.value = {};
}

function switchClick(e, code) {
  form.value[code] = e
}

// 获取设备规格
function getDeviceSpecificationAttributes(deviceId){
  attributes.value = {}
  const api = new DeviceSpecificationAttributes();
  api.get({deviceId: deviceId}).then((response) => {
    console.log("getDeviceSpecificationAttributes => ", response.data)
    if (response.data.msg !== undefined) {
      message.error(response.data.msg)
      return
    }
    attributes.value = response.data
    attributes.value.status?.forEach(item => {
      item.values = JSON.parse(item.values)
    })
    attributes.value.functions?.forEach(item => {
      item.values = JSON.parse(item.values)
    })
  })
}

// 获取设备当前状态
function getStatus(deviceId) {
  statusList.value = []
  const api = new DeviceStatus();
  api.get({deviceId: deviceId}).then(response => {
    confirmLoading.value = false
    if (response.data.msg !== undefined) {
      message.error(response.data.msg)
      return
    }
    response.data?.forEach(item => {
      statusObj.value[item.code] = item.value
      form.value[item.code] = item.value
    })
    statusList.value = response.data
  });
}

// 获取设备支持的指令集合
function getFunction(deviceId) {
  functionList.value = []
  const api = new DeviceFunctions();
  api.get({deviceId: deviceId}).then(response => {
    console.log("getFunction => ", response.data)
    if (response.data.msg !== undefined) {
      message.error(response.data.msg)
      return
    }
    functionList.value = response.data.functions
  });
}

// 下发属性
function sendProperties(commands) {
  const api = new DeviceSendProperties()
  const param = {
    deviceId: deviceId.value,
    commands: JSON.stringify(commands)
  }
  api.post(param).then(response => {
    if (response.data.msg !== undefined) {
      message.error(response.data.msg)
      return
    }
    message.success("success")
    // 获取最新状态
    setTimeout(() => {
      getStatus(deviceId.value)
    }, 2000); // 2秒后执行
  });
}

// 下发动作
function sendAction(commands) {
  console.log("commands => ", commands)
  const api = new DeviceSendAction()
  api.post({deviceId: deviceId.value, commands: commands}).then(response => {
    if (response.data.msg !== undefined) {
      message.error(response.data.msg)
      return
    }
    message.success("success")
  });
}

// 下发指令
function sendFunction(commands) {
  console.log("commands => ", commands)
  const api = new DeviceSendFunction()
  api.post({deviceId: deviceId.value, commands: commands}).then(response => {
    if (response.data.msg !== undefined) {
      message.error(response.data.msg)
      return
    }
    message.success("success")
  });
}

defineExpose({
  showEdit,
});
</script>

<style lang="less">
@import url("style");
</style>
